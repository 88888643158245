<template>
  <div class="detailPage">
    <div class="detailContent">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
          <el-form-item label="角色名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="角色名称"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="dataForm.creater" placeholder="创建人" disabled></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="dataForm.createdAt" placeholder="创建时间" disabled></el-input>
          </el-form-item>
          <el-form-item label="权限">
			<el-radio-group v-model="checkType" size="mini" @change="checkAll">
				<el-radio-button label="全选"></el-radio-button>
				<el-radio-button label="清空"></el-radio-button>
			</el-radio-group>
			<el-tree
				:data="menuList"
				:props="menuListTreeProps"
				node-key="id"
				ref="menuListTree"
				:default-expand-all="false"
				:default-checked-keys="checkedMenu"
				show-checkbox>
			</el-tree>
          </el-form-item>
          <el-form-item class="buttons">
            <el-button @click="back()">取消</el-button>
            <el-button type="primary" @click="dataFormSubmit()">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
  </div>
</template>

<script>
import { treeDataTranslate, dateFormat } from '@/utils';
export default {
	name: 'roleOption',
	data () {
		return {
			dataForm: {
				id: 0,
				name: '',
				remark: '',
				creater: '',
				createAt: ''
			},
			dataRule: {
				name: [
					{ required: true, message: '角色名称不能为空', trigger: 'blur' }
				]
			},
			user: {},
			menuList: [],
			checkedMenu: [],
			menuListTreeProps: {
				label: 'name',
				children: 'children'
			},
			checkType: []
		};
	},
	beforeCreate () {
		if (this.$route.query.id) {
			this.$route.meta.navName = '修改角色';
		} else {
			this.$route.meta.navName = '新增角色';
		}
	},
	created () {
		this.menuSelect();
	},
	mounted () {
		const id = this.$route.query.id;
		if (id) {
			this.dataForm.id = id;
			this.getDetail(id);
		}
	},
	methods: {
		back () {
			this.$router.go(-1);
		},
		// 获取菜单
		menuSelect () {
			this.$root.request('menuSelect', { topLevel: '0' }).then((data) => {
				if (data) {
					this.menuList = treeDataTranslate(data, 'id');
				}
			});
		},
		checkAll () {
			if (this.checkType === '全选') {
				this.setCheckedAll();
			} else if (this.checkType === '清空') {
				this.resetChecked();
			}
		},
		setCheckedAll () {
			var res = [];
			for (var i = 0; i < this.menuList.length; i++) {
				res.push(this.menuList[i].id);
			}
			this.$refs.menuListTree.setCheckedKeys(res);
		},
		resetChecked () {
			this.$refs.menuListTree.setCheckedKeys([]);
		},
		getDetail (id) {
			this.$refs.menuListTree.setCheckedKeys([]);
			// 获取权限对应的菜单列表
			this.$root.request('roleDetail', { id }).then((data) => {
				if (data) {
					this.dataForm = {
						id: this.$route.query.id,
						name: data.name,
						remark: data.remark,
						creater: data.creater,
						createdAt: dateFormat(new Date(data.createdAt), 'yyyy-MM-dd hh:mm:ss'),
						icon: data.icon
					};
					const ids = [];
					data.menus.forEach(m => {
						ids.push(m.menuId);
					});
					this.$refs.menuListTree.setCheckedKeys(ids, true);
					this.checkedMenu = data.menus.map(m => m.menuId);
				}
			});
		},
		// 表单提交
		dataFormSubmit () {
			this.$refs.dataForm.validate((valid) => {
				if (valid) {
					const ids = [].concat(this.$refs.menuListTree.getCheckedKeys(true));
					this.$root.request('roleAddOrUpdate', {
						...this.dataForm,
						menuIds: ids
					}).then((data) => {
						if (data) {
							this.$root.elMsg('操作成功', 'success', () => {
								if (this.$route.query.id) {
									this.back();
								} else {
									this.$refs.dataForm.resetFields();
								}
							}, 1000);
						}
					}).catch(e => {
						this.$root.elMsg('操作失败', 'error');
					});
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
	/deep/ .el-input {
		width: auto !important;
	}
</style>