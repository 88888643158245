var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailPage" }, [
    _c(
      "div",
      { staticClass: "detailContent" },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              model: _vm.dataForm,
              rules: _vm.dataRule,
              "label-width": "80px"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.dataFormSubmit()
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "角色名称", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "角色名称" },
                  model: {
                    value: _vm.dataForm.name,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "name", $$v)
                    },
                    expression: "dataForm.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "备注" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "备注" },
                  model: {
                    value: _vm.dataForm.remark,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "remark", $$v)
                    },
                    expression: "dataForm.remark"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "创建人" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "创建人", disabled: "" },
                  model: {
                    value: _vm.dataForm.creater,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "creater", $$v)
                    },
                    expression: "dataForm.creater"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "创建时间" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "创建时间", disabled: "" },
                  model: {
                    value: _vm.dataForm.createdAt,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "createdAt", $$v)
                    },
                    expression: "dataForm.createdAt"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "权限" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "mini" },
                    on: { change: _vm.checkAll },
                    model: {
                      value: _vm.checkType,
                      callback: function($$v) {
                        _vm.checkType = $$v
                      },
                      expression: "checkType"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "全选" } }),
                    _c("el-radio-button", { attrs: { label: "清空" } })
                  ],
                  1
                ),
                _c("el-tree", {
                  ref: "menuListTree",
                  attrs: {
                    data: _vm.menuList,
                    props: _vm.menuListTreeProps,
                    "node-key": "id",
                    "default-expand-all": false,
                    "default-checked-keys": _vm.checkedMenu,
                    "show-checkbox": ""
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.back()
                      }
                    }
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.dataFormSubmit()
                      }
                    }
                  },
                  [_vm._v("提交")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }